<template>
  <div class="courseUser">
    <div class="pageContol listWrap templateList">
      <div class="framePage">
        <div class="framePage-title">
          <span>
            <em>当前位置：</em>
            <a href="javascript:;">数据统计</a>
            <i>></i>
            <a href="javascript:;" @click="$router.back()">问卷统计</a>
            <i>></i>
            <a href="javascript:;" @click="init" class="cur-a">学员问卷列表</a>
          </span>
        </div>
        <div class="framePage-body">
          <div
            class="operationControl "
            style="display:flex;flex-direction: column;align-items: flex-start"
          >
            <div class="searchbox" style="margin-bottom:15px">
              <div title="手机号码" class="searchboxItem ci-full">
                <span class="itemLabel" style="min-width:6rem">手机号码:</span>
                <el-input
                  v-model="selectData.mobile"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入手机号码"
                />
              </div>
              <div title="身份证号" class="searchboxItem ci-full">
                <span class="itemLabel" style="min-width:6rem">身份证号:</span>
                <el-input
                  v-model="selectData.idcard"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入身份证号"
                />
              </div>
              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </div>
            </div>
          </div>
          <div style="display:flex;padding-top:5px">
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:5rem">班级名称:</span>
              <span>{{ jsonEdit.projectName }}</span>
            </div>
            <div
              title="课程名称"
              class="searchboxItem ci-full"
              style="margin-left:15px"
            >
              <span class="itemLabel" style="min-width:5rem">课程名称:</span>
              <span>{{ jsonEdit.courseName }}</span>
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                />
                <el-table-column
                  label="姓名"
                  align="left"
                  show-overflow-tooltip
                  prop="userName"
                />
                <el-table-column
                  label="身份证号"
                  align="left"
                  show-overflow-tooltip
                  prop="idcard"
                  minWidth="120"
                />
                <el-table-column
                  label="手机号码"
                  align="left"
                  show-overflow-tooltip
                  prop="mobile"
                />
                <el-table-column
                  label="课件名称"
                  align="right"
                  show-overflow-tooltip
                  prop="kpointName"
                />
                <el-table-column
                  label="填写时间"
                  align="right"
                  show-overflow-tooltip
                  prop="writeTime"
                >
                  <template slot-scope="scope">
                    {{ scope.row.writeTime | moment }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="开始学习时间"
                  align="right"
                  show-overflow-tooltip
                  prop="startLearnTime"
                >
                  <template slot-scope="scope">
                    {{ scope.row.startLearnTime | moment}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="学习状态"
                  align="right"
                  show-overflow-tooltip
                  prop="completeState"
                >
                  <template slot-scope="scope">
                    {{ getStates(scope.row.completeState) }}
                  </template>
                </el-table-column>
                <el-table-column label="操作"  align="center">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding:0 5px"
                      v-if="scope.row.kpointName"
                      @click="DailysummaryDetail(scope.row, 'day')"
                      >查看每日问卷</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      v-else
                      style="padding:0 5px"
                      @click="DailysummaryDetail(scope.row, 'all')"
                      >查看期末问卷</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/courseUser",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      selectData: {
        mobile: "",
        idcard: "",
      },
      jsonEdit: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    init() {
      this.jsonEdit = JSON.parse(this.$route.query.editInfo).row;
      this.getTableHeight();
      this.getData();
    },
    getStates(value) {
      let AuditNameList = [
        {
          value: "0",
          label: "学习中",
        },
        {
          value: "1",
          label: "已完成",
        },
        {
          value: "10",
          label: "未学习",
        },
        {
          value: "20",
          label: "学习中",
        },
        {
          value: "30",
          label: "已完成",
        },
        {
          value: "40",
          label: "未完成",
        },
      ];
      for (let i in AuditNameList) {
        if (AuditNameList[i].value == value) {
          return AuditNameList[i].label;
        }
      }
    },
    getData(pageNum) {
      const params = {
        ...this.selectData,
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectCourseId: this.jsonEdit.projectCourseId,
        projectId: this.jsonEdit.projectId,
      };
      this.doFetch({
        url: "/course/survey/config/userPaperList",
        params,
        pageNum,
      });
    },
    DailysummaryDetail(row, stu) {
      this.$router.push({
        path: "/web/set/questionnaireStudentListDetail",
        query: {
          editInfo: JSON.stringify({
            row,
          }),
          projectCourseId: this.jsonEdit.projectCourseId,
          projectId: this.jsonEdit.projectId,
          stu,
          projectName: this.jsonEdit.projectName,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 +6) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function() {},
};
</script>

<style lang="less" scope>
.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
</style>
